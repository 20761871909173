import * as React from "react";
import Layout from "../components/layout.js";

const IndexPage = () => {
  return (
    <Layout>
      <div className="homepage-dummy"></div>
    </Layout>
  );
};

export default IndexPage;
